
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class Numeric extends Vue {
	$refs: {
		input: HTMLInputElement
	}

	@Prop()
	public value: any;

	@Prop({ type: Boolean, default: false })
	readonly: boolean;

	@Prop({ type: Boolean, default: false })
	disabled: boolean;

	@Prop({ type: Boolean, default: false })
	required: boolean;

	@Prop({ type: Number, default: null })
	public max: number | null;

	@Prop({ type: Number, default: null })
	public min: number | null;

	private numericValue: number | null = null;

	mounted () {
		if (this.value) {
			this.numericValue = this.value;
		}
	}

	/**
	 * Observe model changes
	 */
	@Watch("value")
	onValueChanged(newValue: any) {
		this.numericValue = newValue;
	}

	/**
	 * Entry changed - Check max and min and emits input
	 */
	public entryChanged (inputEvent: any) {
		if (!inputEvent.target) {
			return;
		}

		let newValue = null;
		if (inputEvent.target.value) {
			newValue = parseInt(inputEvent.target.value);

			if (this.min != null) {
				newValue = Math.max(newValue, this.min);
			}

			if (this.max != null) {
				newValue = Math.min(newValue, this.max);
			}

			if (this.numericValue == newValue) {
				// If the value hasn't changed, Vue won't re-render, so manually update the value displayed
				this.$refs.input.value = newValue.toString();
				return;
			}
		}
		this.numericValue = newValue;
		this.$emit("input", newValue);
	}
}
