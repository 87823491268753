
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, namespace } from "vuex-class";
import { get } from "lodash";

import "@/scripts/dictionary";
import { FeaturesList } from "@/store/types";
import NavHeader from "@/components/NavHeader.vue";
import { ServerTypeEvent } from "@/types";
import GenericTable, { TableHeader } from "@/components/table/generic-table.vue";
import { ModalItem } from "@/components/table/generic-update-modal.vue";
import Numeric from "@/components/form/Numeric.vue";
import AreaTreeSelect from "@/components/form/AreaTreeSelect.vue";
import api from "@/services/api.service";
import { getTemplateNamesByEventNum } from "@/services/api.mobileRaiseTemplates.service";
import Script from "@/types/sv-data/events/Script";
import ResponseAlarmType from "@/types/sv-data/events/ResponseAlarmType";
import ServerTypeEventPagedResponse from "@/types/sv-data/events/ServerTypeEventPagedResponse";
import { ServerTypeEventExtended } from "@/types/sv-data/events/ServerTypeEvent";
import { ServerTypeEventsPagedQueryParams } from "@/types/sv-data/events/ServerTypeEventsRequest";
import vSelect from "vue-select";
import { ServerTypeOption } from "@/types/sv-data/events/ServerTypeOption";
import { ServerType } from "@/types/sv-data/enums/ServerType";
import { YesNoOption } from "@/store/responses/types";

const Areas = namespace("areas");

interface IEventGrouping {
    title: string,
    value: boolean | null,
}

/**
 * Setup page for manual raise server type events
 */
@Component({
	components: {
		"nav-header": NavHeader,
		"generic-table": GenericTable,
		"vue-select": vSelect
	}
})
export default class EventConfigurationPage extends Vue{
	@Getter("getFeaturesList") featuresList: FeaturesList;
	@Getter("getPermissions") permissions: any;
	@Getter getUserTenantGroupId: number;
	@Getter getFeature: (featuresList: string[]) => boolean;

	private selectedServerType: ServerTypeOption = null;
	private	serverTypeId: number = ServerType.ManualRaise;

	private serverTypeEvents: ServerTypeEventExtended[] = [];
	private responseAlarmTypes: ResponseAlarmType[] = [];
	private actionPlans: Script[] = [];
	private isLoading: boolean = false;
	private totalRecords: number = 0;
	private tenantServerTypes: ServerTypeOption[] = [];

	private mostRecentSearchParams: ServerTypeEventsPagedQueryParams = null;

    private readonly eventGroupingOptions: IEventGrouping[] =
	[
		{
			title: "Use Default",
			value: null,
		},
		{
			title: "Enabled",
			value: true,
		},
		{
			title: "Disabled",
			value: false
		}
	];

	private readonly yesNoNotSet: YesNoOption[] = [
        {
            title: "Not set",
            value: null
        },
		{
            title: "Yes",
            value: true
        },
        {
            title: "No",
            value: false
        }        
    ];

	private get columns (): TableHeader[] {
		const columns : TableHeader[] = [
			{
				title: "Title",
				key: "title",
				order: 1,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Event title",
				searchable: true,
				visible: true,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
			},
			{
				title: "Description",
				key: "description",
				order: 2,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Description",
				searchable: true,
				visible: true,
				dataType: "input",
				sortable: true,
			},
			{
				title: "Integration Type",
				key: "ServerTypeTitle",
				order: 3,
				sortOrder: 0,
				sortOrderReversed: false,
				sortable: true,
				visible: true,
				disable: !this.tenantServerTypesEnabled,
				sortKey: "serverTypeID",
				description: "Type of Integration event originated from."
			},
			{
				title: "Area",
				key: "groupTitle",
				order: 4,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Area",
				searchable: false,
				visible: true,
				dataType: "input",
				sortable: true,
			},
			{
				title: "Default Priority",
				key: "defaultPriority",
				order: 5,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Priority to use unless overridden",
				searchable: false,
				visible: true,
				dataType: "input",
				sortable: true
			},
			{
				title: "Default Action Plan",
				key: "defaultScriptTitle",
				order: 6,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Action plan to use unless overridden",
				searchable: false,
				visible: true,
				dataType: "input",
				sortable: true,
			},
			{
				title: "Default Ignore",
				key: "defaultIgnore",
				description: "Should alarm be ignored by default (not added to the Event Queue).",
				order: 7,
				searchable: false,
				visible: true,
				disable: !this.tenantServerTypesEnabled,
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				useCustomCell: true
			},
			{
				title: "Default Auto Handle",
				key: "defaultAutoHandle",
				description: "Should alarm be automatically handled. (Added to the Event Queue but closed automatically).",
				order: 8,
				searchable: false,
				visible: true,
				disable: !this.tenantServerTypesEnabled,
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				useCustomCell: true
			},
			{
				title: "Only Match Exact Alarms",
				key: "ignoreDefaultAlarm",
				description: "Only trigger response if all fields match incoming alarm.",
				order: 9,
				searchable: false,
				visible: true,
				disable: !this.tenantServerTypesEnabled,
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				useCustomCell: true
			},
			{
				title: "Raise Alarms Serially",
				key: "isRaiseIndividual",
				description: "Do not group with other alarms, so every new alarm will be raised separately.",
				order: 10,
				searchable: false,
				visible: false,
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				disable: !this.raiseAlarmSeriallyEnabled,
				useCustomCell: true
			},
			{
				title: "Default Tag",
				key: "defaultTagTitle",
				order: 11,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Tag to use unless overridden",
				searchable: false,
				visible: true,
				disable: !get(this.featuresList, ["EventTypeSetup", "DefaultTags"]),
				dataType: "input",
				sortable: true,
			},
			{
				title: "Event Code",
				key: "eventCode",
				order: 15,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Event Code",
				searchable: false,
				visible: false,
				disable: !this.tenantServerTypesEnabled,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
			},
			{
				title: "Restore Code",
				key: "restoreCode",
				order: 16,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Restore Code",
				searchable: false,
				visible: false,
				disable: !this.tenantServerTypesEnabled,
				dataType: "input",
				isTermLabel: true,
				sortable: true,
			},
			{
				title: "Requires Restore",
				key: "restorable",
				order: 17,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Requires Restore",
				searchable: false,
				visible: false,
				disable: !this.tenantServerTypesEnabled,
				isTermLabel: true,
				sortable: true,
				useCustomCell: true,
			},
			{
				title: "Can Restore",
				key: "restored",
				order: 18,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Requires Restore",
				searchable: false,
				visible: false,
				disable: !this.tenantServerTypesEnabled,
				isTermLabel: true,
				sortable: true,
				useCustomCell: true,
			},
			{
				title: "Allow Extra Text",
				key: "allowExtraText",
				description: "Allow Extra Text to be used when raising an Alarm.",
				order: 19,
				searchable: false,
				visible: false,
				disable: !this.tenantServerTypesEnabled,
				sortable: false,
				sortOrder: 0,
				sortOrderReversed: false,
				useCustomCell: true
			}
		];

		if(this.situationalAwarenessEnabled)
		{
			columns.push({
				title: "Shown in Situational Awareness",
				key: "showInSituationAwareness",
				order: 12,
				searchable: false,
				visible: false,
				sortable: true,
				sortOrder: 0,
				sortOrderReversed: false,
				useCustomCell: true
			});
		}

		if (this.geofenceEnabled) {
			columns.push({
				title: "Match GeoFence",
				key: "matchGeofence",
				order: 13,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Match geofence for recording",
				searchable: false,
				visible: true,
				dataType: "input",
				useCustomCell: true
			});
			columns.push({
				title: "GeoFence Radius",
				key: "matchRadius",
				order: 14,
				sortOrder: 0,
				sortOrderReversed: false,
				description: "Record within radius (in meters)",
				searchable: false,
				visible: true,
				dataType: "input"
			});
		}

		return columns;
	}

	private get modalItems (): ModalItem[] {
		return [
			{
				title: "Title",
				key: "title",
				dataType: "text",
				required: true,
				maxLength: 200
			},
			{
				title: "Description",
				key: "description",
				dataType: "text",
				maxLength: 100
			},
			{
				title: "Device Type",
				key: "serverTypeID",
				dataType: "vselect3",
				data: this.tenantServerTypes,
				props: {
					reduce: o => o.serverTypeId,
				},
				readOnlyIfEdit: true,
				visible: this.tenantServerTypesEnabled,
				defaultValue: this.serverTypeId
			},
			{
				title: "Area",
				key: "groupID",
				description: "Allow this event to be used here and any areas below",
				dataType: "component",
				readOnly: false,
				required: !this.permissions.isSystemAdmin,
				data: AreaTreeSelect,
				props: {
					reduce: g => g.id,
					requiredPermissions: ["CanEditSiteSetup"],
					clearable: this.permissions.isSystemAdmin
				},
				defaultValue: this.getUserTenantGroupId,
			},
			{
				title: "Default Priority",
				key: "defaultPriority",
				description: "Priority to use unless overridden, maximum 999999",
				dataType: "component",
				data: Numeric,
				max: 999999,
				min: 0,
				props: {
					max: 999999,
					min: 0
				}
			},
			{
				title: "Default Action Plan",
				key: "defaultScript",
				description: "Action plan to use unless overridden",
				dataType: "vselect3",
				data: this.actionPlans,
				props: {
					reduce: o => o.scriptID
				}
			},
			{
				title: "Default Ignore",
				key: "defaultIgnore",
				dataType: "checkbox",
				defaultValue: false,
				visible: this.tenantServerTypesEnabled,
				useCustomCell: true,
				description: "When set all alarms will be completely ignored by the system"
			},
			{
				title: "Default Auto Handle",
				key: "defaultAutoHandle",
				dataType: "checkbox",
				defaultValue: false,
				visible: this.tenantServerTypesEnabled,
			},
			{
				title: "Only Match Exact Alarms",
				key: "ignoreDefaultAlarm",
				dataType: "checkbox",
				data: Boolean,
				defaultValue: false,
				visible: this.tenantServerTypesEnabled,
			},
			{
				title: "Shown in Situational Awareness",
				key: "showInSituationAwareness",
				dataType: "checkbox",
				data: Boolean,
				defaultValue: false,
				visible: this.situationalAwarenessEnabled
			},
			{
				title: "Raise Alarms Serially",
				key: "isRaiseIndividual",
				dataType: "vselect3",
				visible: this.raiseAlarmSeriallyEnabled,
				description: "Raise every alarm of this type to its own event.",
				data: this.eventGroupingOptions,
				props: {
					reduce: options => options.value
				},
				defaultValue: true
			},
			{
				title: "Default Alarm Tag",
				key: "defaultTag",
				description: "Alarm tag to use unless overridden",
				dataType: "vselect3",
				data: this.responseAlarmTypes,
				props: {
					reduce: alarmType => alarmType.responseAlarmTypeID
				},
				visible: get(this.featuresList, ["EventTypeSetup", "DefaultTags"]) ? true : false
			},
			{
				title: "Match Geofence",
				key: "matchGeofence",
				dataType: "checkbox",
				description: "Record nearby cameras when raised",
				visible: this.geofenceEnabled
			},
			{
				title: "Match Geofence Radius",
				description: "Record cameras within this many meters",
				key: "matchRadius",
				visible: this.geofenceEnabled,
				dataType: "component",
				data: Numeric,
				max: 9999,
				min: 0,
				props: {
					max: 9999,
					min: 0
				}
			},
			{
				title: "Event Code",
				key: "eventCode",
				dataType: "Text",
				maxLength: 50,
				visible: this.tenantServerTypesEnabled,
			},
			{
				title: "Restore Code",
				key: "restoreCode",
				dataType: "Text",
				maxLength: 50,
				visible: this.tenantServerTypesEnabled,
			},
			{
				title: "Requires Restore",
				key: "restorable",
				data: Boolean,
				dataType: "checkbox",
				defaultValue: false,
				visible: this.tenantServerTypesEnabled,
			},
			{
				title: "Can Restore",
				key: "restored",
				data: Boolean,
				dataType: "checkbox",
				defaultValue: false,
				visible: this.tenantServerTypesEnabled,
			},
			{
				title: "Allow Extra Text",
				key: "allowExtraText",
				dataType: "vselect3",
				visible: this.tenantServerTypesEnabled,
				data: this.yesNoNotSet,
				props: {
					reduce: options => options.value
				},
				defaultValue: null
			},
		]
	}

	private get tableItems (): ServerTypeEventExtended[]
	{
		let mappedItems: ServerTypeEventExtended[] = this.serverTypeEvents.map(type => {
			let newType: ServerTypeEventExtended = Object.assign(type,
			{
				ServerTypeTitle: this.tenantServerTypes.find(tst => tst.serverTypeId == type.serverTypeID)?.title
			});

			return newType;
		});

		return mappedItems;
	}

	private async mounted () {
		if (!this.eventTypeManagementEnabled || !this.permissions.canEditSettings) {
			this.$router.go(-1);
		}

		if(this.tenantServerTypesEnabled)
		{
			this.tenantServerTypes = await api.getServerTypesForTenant();
			// there is a watch on selectedServerType which calls updateState.
			this.selectedServerType = this.tenantServerTypes.find(type => type.serverTypeId == ServerType.ManualRaise);
		}
		else
		{
			this.updateState();
		}
	}

	private async updateState(ServerTypeEventsPagedQueryParams?: ServerTypeEventsPagedQueryParams) {
		try {
			this.isLoading = true;
			if(!ServerTypeEventsPagedQueryParams){
				ServerTypeEventsPagedQueryParams = {
					page: 1,
					pageSize: 25,
					sortDesc: false,
					sortBy: "Title",
					searchTerm: ""
				}
			}
			this.mostRecentSearchParams = ServerTypeEventsPagedQueryParams;

			// Ensure were looking for manual raises
			ServerTypeEventsPagedQueryParams.serverTypeIds = [this.serverTypeId];

            let serverEventTypeResponse: ServerTypeEventPagedResponse = await api.pagedServerTypeEvents(ServerTypeEventsPagedQueryParams);

			this.totalRecords = serverEventTypeResponse.totalRecords

			this.serverTypeEvents = serverEventTypeResponse.data

			this.actionPlans = await api.loadActionPlans();
			this.responseAlarmTypes = await api.responseAlarmTypes();
		} catch (ex) {
			console.log("Unexpected error updating component state: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private get raiseAlarmSeriallyEnabled(): boolean {
		return this.getFeature(["EventTypeSetup", "TenantServerTypes", "EditRaiseAlarmsSerially"]);
	}

	private get eventTypeManagementEnabled () {
		return get(this.featuresList, ["EventTypeSetup"]);
	}

	private get tenantServerTypesEnabled () : boolean
	{
		return this.getFeature(["EventTypeSetup", "TenantServerTypes"]);
	}

	private get situationalAwarenessEnabled () : boolean
	{
		return this.getFeature(["SituationalAwareness"]);
	}

	private get geofenceEnabled () : boolean
	{
		return this.getFeature(["EventTypeSetup", "Geofence"]);
	}


	private async updateItem (updateEvent: ServerTypeEvent) {
		if (updateEvent) {
			try {
				this.isLoading = true;
				// ensure that not null boolean are not null!
				updateEvent.defaultIgnore = updateEvent.defaultIgnore || false;
				updateEvent.defaultAutoHandle = updateEvent.defaultAutoHandle || false;
				updateEvent.ignoreDefaultAlarm = updateEvent.ignoreDefaultAlarm || false;
				updateEvent.serverTypeID = updateEvent.serverTypeID || this.serverTypeId;
				updateEvent.restorable = updateEvent.restorable || false;
				updateEvent.restored = updateEvent.restored || false;

				await api.serverTypeEventUpdate(updateEvent);
				await this.updateState(this.mostRecentSearchParams);
			} catch (ex) {
				console.log("Unexpected error updating server type event: " + ex);
			} finally {
				this.isLoading = false;
			}
		}
	}

	private async deleteItem (deleteEvent: ServerTypeEvent) {
		try {
			this.isLoading = true;
			await api.serverTypeEventDelete(deleteEvent);
			await this.updateState(this.mostRecentSearchParams);
		} catch (ex) {
			console.log("Unexpected error deleting server type event: " + ex);
		} finally {
			this.isLoading = false;
		}
	}

	private async deleteActionMessages(eventToDelete: ServerTypeEvent): Promise<string[]> {
		const usedInTemplates = await getTemplateNamesByEventNum(eventToDelete.eventNum);
		if (usedInTemplates && usedInTemplates.length) {
			const msg = `Remove this Event from Mobile Raise Templates: ${usedInTemplates.join(", ")}`;
			return [msg];
		}
		return [];
	}

	@Watch("selectedServerType")
	private onServerTypeChange(): void
	{
		this.serverTypeId = this.selectedServerType?.serverTypeId || ServerType.ManualRaise;
		this.updateState();
	}
}
